(function () {
  'use strict';

  function PaginatorError(message, code) {
    this.name = 'PaginatorError';
    this.message = message || 'Paginator Error';
    this.code = code;
    this.stack = new Error().stack;
  }
  PaginatorError.prototype = Object.create(Error.prototype);
  PaginatorError.prototype.constructor = PaginatorError;

  function PaginatorBase($config, strategy, options) {
    checkRequirements(options);

    this.setStrategy(strategy);
    this._initializeProperties($config, options);

    this.strategy.requestRows(false, this.paginationModel);
  }

  PaginatorBase.prototype.moreRows = function () {
    this.strategy.requestRows(true, this.paginationModel);
  };

  PaginatorBase.prototype.sortBy = function (columnName, options) {
    options = options || { firstDirection: 1 };

    var overrides = {};
    if (!this.isNotLimited() && this.data.length >= this.paginationModel.limit) {
      overrides.limit = this.data.length;
      overrides.hasMoreRows = this.strategy.hasMoreRows;
    }

    this.paginationModel.skip = 0;
    if (columnName === this.paginationModel.sort) {
      this.paginationModel.direction *= -1;
    } else {
      this.paginationModel.direction = options.firstDirection || 1;
      this.paginationModel.sort = columnName;
    }

    this.strategy.requestRows(false, this.paginationModel, overrides);
  };

  PaginatorBase.prototype.isSortedBy = function (columnName) {
    return columnName === this.paginationModel.sort;
  };

  PaginatorBase.prototype.getSortDirection = function () {
    return this.paginationModel.direction;
  };

  PaginatorBase.prototype.reset = function (shouldClearData) {
    this.strategy.reset(shouldClearData);

    this.paginationModel.skip = 0;

    this.strategy.requestRows(false, this.paginationModel);
  };

  PaginatorBase.prototype.isNotLimited = function () {
    return this.paginationModel.limit === undefined;
  };

  PaginatorBase.prototype.retryLastRequest = function () {
    return this.strategy.retryLastRequest();
  };

  PaginatorBase.prototype.getPromiseForRequest = function (request) {
    var promise = this.pagingFunction(request.model, request.appendResult);
    if (!promise || !promise.then) {
      throw new Error('pagingFunction should return a promise object');
    }

    var successPipeline = _.bind(
      this.strategy.successPipeline.transform,
      this.strategy.successPipeline,
      request
    );
    var errorPipeline = _.bind(
      this.strategy.errorPipeline.transform,
      this.strategy.errorPipeline,
      request
    );

    return promise.then(successPipeline, errorPipeline);
  };

  PaginatorBase.prototype.addSortingPosition = function (data) {
    SportContract.utils.addSortingPosition(data, this.paginationModel.sort);
  };

  PaginatorBase.prototype.setStrategy = function (strategy) {
    this.strategy = strategy;
    this.strategy.getPromiseForRequest = _.bind(this.getPromiseForRequest, this);

    this.strategy.successPipeline.unshift({ fn: sortResponseData, context: this });
    this.strategy.successPipeline.push({ fn: handlePagingSuccess, context: this });

    this.strategy.errorPipeline.push({ fn: handlePagingError, context: this });
  };

  PaginatorBase.prototype.isBusy = function () {
    return this.strategy.isBusy();
  };

  PaginatorBase.prototype._initializeProperties = function ($config, options) {
    this.paginationModel = PaginatorBase.PaginationBuilderFactory.addSkip(options.skip || 0)
      .addLimit(options.limit || $config.defaultRowsCount)
      .addSort(options.sortBy)
      .addDirection(options.direction)
      .build();

    this.__defineGetter__(
      'hasMoreRow',
      _.bind(function () {
        return this.strategy.hasMoreRows;
      }, this)
    );
    this.__defineGetter__(
      'data',
      _.bind(function () {
        return this.strategy.data;
      }, this)
    );
    this.__defineGetter__(
      'lastPromise',
      _.bind(function () {
        return this.strategy.lastPromise;
      }, this)
    );

    this.pagingFunction = options.pagingFunction;
    this.sortFunction = options.sortFunction;
    this.pagingSuccess = options.pagingSuccess;
    this.pagingFailed = options.pagingFailed;
  };

  function checkRequirements(options) {
    if (!options) {
      throw new Error('`options` has to be provided as an option when instantiate paginator');
    }

    if (!options.pagingFunction || typeof options.pagingFunction !== 'function') {
      throw new Error(
        '`pagingFunction` has to be provided as an option when instantiate paginator'
      );
    }
  }

  function sortResponseData(request, data) {
    if (typeof this.sortFunction === 'function') {
      return this.sortFunction(request.model, data);
    }

    return data;
  }

  function handlePagingSuccess(request, data) {
    if (data) {
      this.paginationModel.skip += data.length;

      if (typeof this.pagingSuccess === 'function') {
        this.pagingSuccess(data, request.appendResult);
      }
    }
  }

  // if pagingFailed function is provided, we dont' throw the error
  function handlePagingError(request, error) {
    if (typeof this.pagingFailed === 'function') {
      this.pagingFailed(error);
      return error;
    } else {
      var message = _.get(error, 'data.message');
      var code = _.get(error, 'status') || _.get(error, 'data.code');

      return PaginatorBase.$q.reject(new PaginatorError(message, code));
    }
  }

  function paginatorFactory($injector, $q, PaginationBuilderFactory, PipelineTransformer) {
    PaginatorBase.$q = $q;
    PaginatorBase.PaginationBuilderFactory = PaginationBuilderFactory;

    function getPaginatorInstance($config, opts) {
      var strategy = opts.strategy || $config.defaultStrategy;

      var StrategyClass = $injector.get(strategy),
        StrategyInstance = new StrategyClass(PipelineTransformer);

      return new PaginatorBase($config, StrategyInstance, opts);
    }

    return { getInstance: getPaginatorInstance };
  }

  angular.module('app.common').factory('PaginatorBaseFactory', paginatorFactory);
})();
