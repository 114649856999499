(function () {
  'use strict';

  /**
   * Simplifies the handling of the pagination.
   **/
  function PaginationAwareFactory(PaginationBuilderFactory) {
    var paginationModel;

    function initScope($scope, sort, limit, direction) {
      $scope.addRows = function (number) {
        paginationModel.limit += number;

        $scope.paginationModel = getPaginationModel();
      };

      $scope.sortBy = function (columnName) {
        if (columnName === paginationModel.sort) {
          paginationModel.direction = paginationModel.direction == 1 ? -1 : 1;
        } else {
          paginationModel.direction = 1;
          paginationModel.sort = columnName;
        }

        $scope.paginationModel = getPaginationModel();
      };

      $scope.isSortedBy = function (columnName) {
        if (columnName === paginationModel.sort) {
          return true;
        }

        return false;
      };

      $scope.getSortDirection = function () {
        return paginationModel.direction;
      };

      paginationModel = PaginationBuilderFactory.addLimit(limit)
        .addSort(sort)
        .addDirection(direction)
        .build();

      $scope.paginationModel = paginationModel;
    }

    var paginationListener = function ($scope, callBackFunc) {
      $scope.$watch(
        'paginationModel',
        function (newVal, oldVal) {
          if (!angular.equals(newVal, oldVal)) {
            callBackFunc(newVal);
          }
        },
        true
      );
    };

    var getPaginationModel = function () {
      return PaginationBuilderFactory.addSkip(paginationModel.skip)
        .addLimit(paginationModel.limit)
        .addDirection(paginationModel.direction)
        .addSort(paginationModel.sort)
        .build();
    };

    var getFieldValue = function (res, sortedField) {
      var tempData;

      if (sortedField instanceof Array) {
        for (var field in sortedField) {
          if (angular.isUndefined(tempData)) {
            tempData = res[sortedField[field]];
          } else {
            tempData = tempData[sortedField[field]];
          }
        }
      } else {
        tempData = res[sortedField];
      }

      return tempData;
    };

    var addSortingNumberToResult = function (result) {
      if (result && result instanceof Array) {
        var sortedField = paginationModel.sort;
        var i = 1;
        var lastData;

        if (sortedField.indexOf('.') != -1) {
          sortedField = sortedField.split('.');
        }

        for (var res in result) {
          var currData = getFieldValue(result[res], sortedField);

          if (angular.isDefined(lastData) && lastData !== currData) {
            i++;
          }

          angular.extend(result[res], { sortingPosition: i });
          lastData = currData;
        }
      }

      return result;
    };

    return {
      initScope: initScope,
      paginationListener: paginationListener,
      getPaginationModel: getPaginationModel,
      addSortingNumberToResult: addSortingNumberToResult,
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.factory('PaginationAwareFactory', PaginationAwareFactory);
})();
