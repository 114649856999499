(function () {
  'use strict';

  angular.module('app.common').directive('scSubmitOnEnter', ScSubmitOnEnter);

  function ScSubmitOnEnter() {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attrs) {
        $element.bind('keydown keypress', function (event) {
          if (event.which === 13 && !event.shiftKey) {
            $scope.$apply(function () {
              $scope.$eval($attrs.scSubmitOnEnter, { event: event });
            });

            event.preventDefault();
          }
        });
      },
    };
  }
})();
