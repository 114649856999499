(function () {
  angular.module('app.common').component('seasonSwitcher', {
    templateUrl: 'common/common/components/season-switcher.html',
    controller: SeasonSwitcherController,
    bindings: {
      season: '<',
      onChange: '&',
    },
  });

  function SeasonSwitcherController($state, UserService, SeasonService) {
    var $ctrl = this;

    $ctrl.init = init;
    $ctrl.changeSeason = changeSeason;

    $ctrl.$onChanges = function (changesObj) {
      if (changesObj && changesObj.season && changesObj.season.currentValue) {
        if ($ctrl.selectedSeason) {
          $ctrl.selectedSeason.val = changesObj.season.currentValue;
        } else {
          $ctrl.selectedSeason = {
            val: changesObj.season.currentValue,
          };
        }
      }
    };

    $ctrl.init();

    function init() {
      $ctrl.seasons = SeasonService.getSeasonList();
      $ctrl.selectedSeason = {
        val: SeasonService.getSeason(),
      };
    }

    function changeSeason(season) {
      if (typeof $ctrl.onChange() === 'function') {
        $ctrl.onChange({ $season: season });
      } else {
        SeasonService.setSeason(season);
        UserService.setSeason(season);
        $state.go($state.$current, null, { reload: true });
      }
    }
  }
})();
