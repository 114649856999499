(function () {
  'use strict';

  angular.module('app.common').service('CountryService', CountryService);

  function CountryService($resource, SCConfiguration) {
    var countryResource = $resource(
      SCConfiguration.getEndpoint() + '/api/countries/:countryId/:associations',
      {},
      {
        getList: {
          method: 'GET',
          isArray: true,
          cache: true,
        },
        getCountriesWithLeagues: {
          method: 'GET',
          isArray: true,
          cache: true,
          params: {
            countryId: 'with-leagues',
          },
        },
        getCountriesWithOrganizations: {
          method: 'GET',
          isArray: true,
          cache: true,
          params: {
            countryId: 'with-organizations',
          },
        },
        getAssociations: {
          method: 'GET',
          params: {
            associations: 'associations',
          },
          isArray: true,
        },
        getLeagues: {
          method: 'GET',
          params: {
            associations: 'leagues',
          },
          isArray: true,
        },
        getTeams: {
          method: 'GET',
          params: {
            associations: 'teams',
          },
          isArray: true,
        },
      }
    );

    return {
      getList: getList,
      fetch: fetch,
      getAssociations: getAssociations,
      getLeagues: getLeagues,
      getTeams: getTeams,
      getCountriesWithLeagues: getCountriesWithLeagues,
      getCountriesWithOrganizations: getCountriesWithOrganizations,
    };

    function getList() {
      return countryResource.getList().$promise;
    }

    function fetch() {
      return countryResource.getList();
    }

    function getCountriesWithLeagues() {
      return countryResource.getCountriesWithLeagues().$promise;
    }

    function getCountriesWithOrganizations() {
      return countryResource.getCountriesWithOrganizations().$promise;
    }

    function getAssociations(countryId) {
      return countryResource.getAssociations({ countryId: countryId }).$promise;
    }

    function getLeagues(countryId) {
      return countryResource.getLeagues({ countryId: countryId }).$promise;
    }

    function getTeams(countryId) {
      if (!countryId) {
        throw new Error('CountryService.getTeams: Missing country Id');
      }
      return countryResource.getTeams({ countryId: countryId }).$promise;
    }
  }
})();
