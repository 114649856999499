(function () {
  'use strict';

  function sortMarkerDirective() {
    return {
      restrict: 'A',
      scope: { paginator: '<' },
      templateUrl: 'common/common/views/sortMarkerDirective.html',

      link: function ($scope, $element, $attr) {
        $scope.sortedColumnName = $attr.sortMarker;
        $scope.sortedColumnType = $attr.type || 'alpha';
      },
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.directive('sortMarker', sortMarkerDirective);
})();
