class ScPlayerControlsController {
  private API;
  private playPauseIcon;
  private allowSpeeds;
  private fastForwardSpeeds;
  private selectedSpeed;
  private speed;
  private direction;
  private $rewindInterval;
  private speedSelectorVisible;

  constructor(
    private $scope,
    private $document,
    private $interval,
    private hotkeys,
    private VG_STATES,
  ) {
    this.playPauseIcon = { play: true };
    this.speed = 1;
    this.selectedSpeed = 1;
    this.allowSpeeds = [0.25, 0.5, 0.75, 1, 2, 4, 6];
    this.fastForwardSpeeds = [0.25, 0.5, 0.75, 1, 2, 4, 6, 8];
    this.direction = 0;
    this.speedSelectorVisible = false;
  }

  $onInit() {
    this.$scope.$watch(
      () => this.API.currentState,
      (newVal, _oldVal) => this.setState(newVal),
    );
    const isMacOS = navigator.userAgent.indexOf('Mac OS') > -1;
    const alt = isMacOS ? 'option' : 'alt';

    this.hotkeys
      .bindTo(this.$scope)
      .add({
        combo: `${alt}+up`,
        description: 'Reset speed',
        callback: () => this.resetSpeed(),
      })
      .add({
        combo: `${alt}+right`,
        description: 'Speed up the playback speed',
        callback: () => this.speedUpPlaybackSpeed(),
      })
      .add({
        combo: `${alt}+left`,
        description: 'Slow down the playback speed',
        callback: (e) => {
          e.preventDefault(); // prevent browser back on Windows / Linux
          this.slowDownPlaybackSpeed();
        },
      });
  }

  setState(newState) {
    switch (newState) {
      case this.VG_STATES.PLAY:
        this.playPauseIcon = { 'fa-pause': true };
        break;

      case this.VG_STATES.PAUSE:
        this.playPauseIcon = { 'fa-play': true };
        this.resetSpeed();
        break;

      case this.VG_STATES.STOP:
        this.playPauseIcon = { 'fa-play': true };
        this.resetSpeed();
        break;
    }
  }

  resetSpeed() {
    this.speed = this.selectedSpeed;
    this.direction = 0;
    this.API.setPlayback(this.selectedSpeed);

    if (this.$rewindInterval) {
      this.$interval.cancel(this.$rewindInterval);
    }
  }

  blur() {
    this.$document.find('videogular button:focus').blur();
  }

  playPause() {
    if (this.speed !== this.selectedSpeed) {
      this.resetSpeed();
    } else {
      this.API.playPause();
    }

    this.blur();
  }

  /**
   * Currently the rewind feature doesn't work well in major browsers
   * We keep this function for the later implement when they provide the support
   */
  rewind() {
    this.blur();

    if (this.$rewindInterval) {
      this.$interval.cancel(this.$rewindInterval);
    }

    if (this.direction === 1) {
      this.speed = this.selectedSpeed;
    }

    this.speed = this.nextSpeed();

    if (this.speed === this.selectedSpeed) {
      this.direction = 0;
      this.$interval.cancel(this.$rewindInterval);
      return;
    }

    this.API.setPlayback(1);
    this.direction = -1;

    if (this.API.currentState !== this.VG_STATES.PLAY) {
      this.API.play();
    }

    this.$rewindInterval = this.$interval(() => {
      if (this.API.currentTime <= 0) {
        this.$interval.cancel(this.$rewindInterval);
        this.speed = this.selectedSpeed;
        this.direction = 0;
        this.API.pause();
        return;
      } else {
        this.API.seekTime(Math.max(this.API.currentTime / 1000 - 0.024 * this.speed, 0));
      }
    }, 30);
  }

  fastForward(nextSpeed) {
    this.blur();

    if (this.$rewindInterval) {
      this.$interval.cancel(this.$rewindInterval);
    }

    if (this.direction === -1) {
      this.speed = this.selectedSpeed;
    }

    this.speed = nextSpeed;
    this.API.setPlayback(this.speed);

    if (this.speed === this.selectedSpeed) {
      this.direction = 0;
      return;
    }

    this.direction = 1;

    if (this.API.currentState !== this.VG_STATES.PLAY) {
      this.API.play();
    }
  }

  nextSpeed() {
    const idx = this.fastForwardSpeeds.indexOf(this.speed);
    return idx + 1 === this.fastForwardSpeeds.length
      ? this.selectedSpeed
      : this.fastForwardSpeeds[idx + 1];
  }

  previousSpeed() {
    const idx = this.fastForwardSpeeds.indexOf(this.speed);
    return idx - 1 < 0 ? this.selectedSpeed : this.fastForwardSpeeds[idx - 1];
  }

  selectSpeed(speed) {
    this.selectedSpeed = speed;
    this.resetSpeed();
    this.speedSelectorVisible = false;
    this.API.setPlayback(this.selectedSpeed);
  }

  private speedUpPlaybackSpeed() {
    const nextSpeed = this.nextSpeed();
    this.fastForward(nextSpeed);
  }

  private slowDownPlaybackSpeed() {
    const nextSpeed = this.previousSpeed();
    this.fastForward(nextSpeed);
  }
}

angular.module('app.video').component('scPlayerControls', {
  template: `
        <button class="sc-control-btn backward" ng-class="{active: $ctrl.speed < 1}" ng-click="$ctrl.slowDownPlaybackSpeed()" type="button">
            <span ng-show="$ctrl.speed < 1">{{$ctrl.speed}}x</span>
            <i class="fa fa-backward"></i>
        </button>
        <button class="sc-control-btn play" ng-click="$ctrl.playPause()" type="button">
            <i class="fa" ng-class="$ctrl.playPauseIcon"></i>
        </button>
        <button class="sc-control-btn forward" ng-class="{active: $ctrl.speed > 1}" ng-click="$ctrl.speedUpPlaybackSpeed()" type="button">
            <i class="fa fa-forward"></i>
            <span ng-show="$ctrl.speed > 1">{{$ctrl.speed}}x</span>
        </button>
        <button class="sc-control-btn" ng-click="$ctrl.speedSelectorVisible = !$ctrl.speedSelectorVisible">
            {{$ctrl.selectedSpeed}}x
        </button>
        <span class="speed-selector" ng-show="$ctrl.speedSelectorVisible">
            <button
                class="sc-control-btn"
                ng-repeat="speed in $ctrl.allowSpeeds"
                ng-click="$ctrl.selectSpeed(speed)"
                ng-class="{'selected': speed === this.selectedSpeed}"
            >{{speed}}x</button>
        </span>
    `,
  require: {
    API: '^videogular',
  },
  bindings: {},
  controller: ScPlayerControlsController,
});
