(function () {
  'use strict';

  var countryNameFallback = {
    russia: 'russianfederation',
    'czechrep.': 'czech',
    czechia: 'czech',
    latviaflag: 'latvia',
    'u.k.': 'unitedkingdom',
  };

  var availableCountryFlags = [
    'albania',
    'algeria',
    'andorra',
    'angola',
    'antiguaandbarbuda',
    'argentina',
    'armenia',
    'aruba',
    'australia',
    'austria',
    'azerbaijan',
    'bahrain',
    'bangladesh',
    'barbados',
    'belarus',
    'belgium',
    'benin',
    'bermuda',
    'bhutan',
    'bolivia',
    'bosnia',
    'bosniaandherzegovina',
    'botswana',
    'brazil',
    'bulgaria',
    'burkinafaso',
    'burma(myanmar)',
    'burundi',
    'cambodia',
    'cameroon',
    'canada',
    'capeverde',
    'centralafricanrepublic',
    'chad',
    'chile',
    'china',
    'colombia',
    'comoros',
    'congo',
    'costarica',
    'croatia',
    'cuba',
    'curaçao',
    'cyprus',
    'czech',
    'czechia',
    'czechrep.',
    'czechrepublic',
    'denmark',
    'djibouti',
    'dominicanrepublic',
    'ecuador',
    'egypt',
    'elsalvador',
    'england',
    'equatorialguinea',
    'eritrea',
    'estonia',
    'ethiopia',
    'faroeislands',
    'fiji',
    'finland',
    'france',
    'gabon',
    'gambia',
    'georgia',
    'germany',
    'ghana',
    'gibraltar',
    'greece',
    'grenada',
    'guatemala',
    'guinea-bissau',
    'guinea',
    'haiti',
    'honduras',
    'hongkong',
    'hungary',
    'iceland',
    'india',
    'indonesia',
    'international',
    'iran',
    'iraq',
    'ireland',
    'israel',
    'italy',
    'jamaica',
    'japan',
    'jordan',
    'kazakhstan',
    'kenya',
    'kuwait',
    'kyrgyzstan',
    'laos',
    'latvia',
    'lebanon',
    'lesotho',
    'liberia',
    'libya',
    'liechtenstein',
    'lithuania',
    'luxembourg',
    'macau',
    'macedonia',
    'madagascar',
    'malawi',
    'malaysia',
    'maldives',
    'mali',
    'malta',
    'mauritania',
    'mauritius',
    'mexico',
    'moldova',
    'mongolia',
    'montenegro',
    'morocco',
    'mozambique',
    'myanmar',
    'namibia',
    'netherlands',
    'newzealand',
    'nicaragua',
    'niger',
    'nigeria',
    'northernireland',
    'norvey',
    'norway',
    'oman',
    'pakistan',
    'panama',
    'paraguay',
    'peru',
    'phillipines',
    'poland',
    'portugal',
    'qatar',
    'romania',
    'russia',
    'russianfederation',
    'rwanda',
    'sanmarino',
    'saotomeandprincipe',
    'saudiarabia',
    'scotland',
    'senegal',
    'serbia',
    'seychelles',
    'sierraleone',
    'singapore',
    'slovakia',
    'slovenia',
    'somalia',
    'southafrica',
    'southafrica',
    'southkorea',
    'southsudan',
    'spain',
    'srilanka',
    'stateofpalestine',
    'sudan',
    'suriname',
    'sweden',
    'switzerland',
    'syria',
    'syrianarabrepublic',
    'tajikistan',
    'thailand',
    'thedemocraticrepublicofcongo',
    'togo',
    'trinidadandtobago',
    'tunisia',
    'turkey',
    'turkmenistan',
    'u.k.',
    'uae',
    'uganda',
    'ukraine',
    'unitedarabemirates',
    'unitedkingdom',
    'unitedrepublicoftanzania',
    'uruguay',
    'usa',
    'uzbekistan',
    'venezuela',
    'vietnam',
    'virginislands-british',
    'wales',
    'yemen',
    'zambia',
    'zimbabwe',
    "côted'ivoire",
  ];

  function getCountryFlagImageUrl(countryName, type) {
    type = type || 'club';

    if (countryName.indexOf('.jpg') !== -1) {
      return '/img/large-flags/' + countryName;
    }

    if (availableCountryFlags.indexOf(countryName) === -1) {
      return '/img/phimg-' + type + '.jpg';
    }

    if (countryNameFallback[countryName]) {
      countryName = countryNameFallback[countryName];
    }
    return '/img/large-flags/' + countryName + '.jpg';
  }

  function getLeagueImageUrl(baseUrl, url) {
    if (url.indexOf('leagues/') === 0 || url.indexOf('logos/') === 0) {
      return baseUrl + '/' + url;
    }

    return baseUrl + '/img/' + url;
  }

  function scImageFilter(IMAGE_END_POINT) {
    return function (input, type) {
      var result = '/img/phimg-player.jpg';

      switch (type) {
        case 'country':
          input = input.toLowerCase().replace(/\s/g, '');
          result = getCountryFlagImageUrl(input, 'country');
          break;
        case 'player':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-player.jpg';
          break;
        case 'staff':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-player.jpg';
          break;
        case 'agent':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-player.jpg';
          break;
        case 'league':
          result = input ? getLeagueImageUrl(IMAGE_END_POINT, input) : '/img/phimg-club.jpg';
          break;
        case 'team':
        case 'nat_team':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-club.jpg';
          break;
        case 'agency':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-club.jpg';
          break;
        case 'company':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-club.jpg';
          break;
        case 'union':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-club.jpg';
          break;
        case 'arena':
          result = input ? IMAGE_END_POINT + '/' + input : '/img/phimg-country.jpg';
          break;
      }

      return result;
    };
  }

  angular.module('app.common').filter('scImage', scImageFilter);
})();
