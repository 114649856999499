(function () {
  'use strict';

  angular.module('app.common').service('Toastr', ToastrService);

  function ToastrService() {
    return {
      success: function (msg, pos, timeout, onclick) {
        if (toastr) {
          toastr.success(msg, '', {
            positionClass: pos || 'toast-bottom-right',
            timeOut: timeout,
            onclick: onclick,
          });
        }
      },

      info: function (msg, pos, timeout) {
        if (toastr) {
          toastr.info(msg, '', { positionClass: pos || 'toast-bottom-right', timeOut: timeout });
        }
      },

      error: function (msg, pos, timeout) {
        if (toastr) {
          toastr.error(msg, '', { positionClass: pos || 'toast-bottom-right', timeOut: timeout });
        }
      },

      warning: function (msg, pos, timeout) {
        if (toastr) {
          toastr.warning(msg, '', { positionClass: pos || 'toast-bottom-right', timeOut: timeout });
        }
      },
    };
  }
})();
