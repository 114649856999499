(function () {
  'use strict';

  var PROMISE_PENDING = 0,
    PROMISE_RESOLVED = 1,
    PROMISE_REJECTED = 2;

  var PROMISE_HANDLERS = {
    0: 'handlerForLoadingState',
    1: 'handlerForLoadedState',
    2: 'handlerForErrorState',
  };

  angular
    .module('app.common')
    .directive('loadingWrapper', loadingWrapperDirective)
    .controller('LoadingWrapperController', LoadingWrapperController);

  function loadingWrapperDirective($compile, $q, $animate) {
    function checkRequirements($attr) {
      if ($attr.wait === undefined) {
        throw new Error(
          'You should specify promise with "wait" attribute of loading-wrapper directive'
        );
      }
    }

    return {
      restrict: 'A',
      scope: { wait: '=', retryFunction: '&onRetry' },

      transclude: 'element',
      controller: 'LoadingWrapperController',

      link: function ($scope, $element, $attr, $controller, $transclude) {
        checkRequirements($attr);

        var elements = {
          placeholder: $element,
          content: null,
          loading: compileLoadingElement($scope),
          error: angular.element('<loading-error>'),
        };

        registerRetryFunction($scope, $attr, $controller);
        registerHandlers($scope, $attr, $controller, $transclude, elements);

        $scope.$watch('wait', function () {
          $controller.setPromise($scope.wait);
        });
      },
    };

    function setElementSizeAccordingToParent(element, parentElement) {
      prepareParentElement(parentElement);

      setTimeout(function () {
        element.height(Math.max(50, parentElement.height()));
      }, 100);
      element.addClass('overlay');
    }

    function prepareParentElement(element) {
      element.addClass('has-loading-wrapper');
    }
    function releaseParentElement(element) {
      element.removeClass('has-loading-wrapper');
    }

    function registerHandlers($scope, $attr, $controller, $transclude, elements) {
      $controller.handlerForLoadingState = function () {
        $animate.leave(elements.error);
        releaseParentElement(elements.placeholder.parent());

        if ($attr.isFixedSize === undefined) {
          setElementSizeAccordingToParent(elements.loading, elements.placeholder.parent());
        }

        $animate.enter(elements.loading, elements.placeholder.parent(), elements.placeholder);
      };

      $controller.handlerForLoadedState = function () {
        $animate.leave(elements.error);
        $animate.leave(elements.loading);
        releaseParentElement(elements.placeholder.parent());

        if (!elements.content) {
          $transclude(function (clone) {
            elements.content = clone;
            $animate.enter(elements.content, elements.placeholder.parent(), elements.placeholder);
          });
        }
      };

      $controller.handlerForErrorState = function (error) {
        $animate.leave(elements.loading);
        releaseParentElement(elements.placeholder.parent());

        elements.error = compileErrorElement(elements.error, $scope, $controller);

        if ($attr.isFixedSize === undefined) {
          setElementSizeAccordingToParent(elements.error, elements.placeholder.parent());
        }

        $animate.enter(elements.error, elements.placeholder.parent(), elements.placeholder);

        console.error(error);
      };
    }
    function registerRetryFunction($scope, $attr, $controller) {
      if ($attr.onRetry) {
        $controller.setRetryFunction($scope.retryFunction);
      }
    }

    function compileErrorElement(errorElement, $scope, $controller) {
      return $compile(errorElement)($scope, null, {
        transcludeControllers: {
          loadingWrapper: { instance: $controller },
        },
      });
    }
    function compileLoadingElement($scope) {
      return $compile('<loading>')($scope);
    }
  }

  function LoadingWrapperController($q) {
    this.tryAgain = tryAgain;
    this.isRetryAvailable = isRetryAvailable;
    this.setRetryFunction = setRetryFunction;

    this.setPromise = setPromise;
    this.onReady = angular.noop;

    this.retryFunction = angular.noop;
    this.handlerForLoadingState = angular.noop;
    this.handlerForLoadedState = angular.noop;
    this.handlerForErrorState = angular.noop;

    this.blockRendering = blockRendering;
    this.allowRendering = allowRendering;

    var vm = this,
      promise,
      activeState,
      isPromiseHandled = false,
      isRenderingBlocked = false;

    resetRetryCount();

    function tryAgain() {
      if (!vm.isRetryAvailable()) {
        throw new Error('`tryAgain()` requirements does not satisfied!');
      }

      var rawPromise = vm.retryFunction();
      if (!rawPromise || !rawPromise.then) {
        throw new Error('`retryFunction()` should return promise!');
      }

      setPromise(rawPromise);
      promise.then(resetRetryCount, decrementRetryCount);
    }

    function isRetryAvailable() {
      return vm.retryFunction !== angular.noop && vm.retriesLeft >= 0;
    }
    function setRetryFunction(fn) {
      vm.retryFunction = fn;
    }

    function resetRetryCount() {
      vm.retriesLeft = 2;
    }
    function decrementRetryCount() {
      vm.retriesLeft -= 1;
      // TODO: log somewhere if controller.retriesLeft < 0
    }

    function setPromise(value) {
      if (value && value.$promise) {
        promise = value.$promise;
      } else if (value && typeof value === 'object' && value.then === undefined) {
        promise = $q.all(value);
      } else if (value !== undefined && value !== null) {
        promise = $q.when(value);
      }

      if (activeState !== PROMISE_PENDING) {
        activeState = undefined;
        isPromiseHandled = false;
      }

      listenForPromiseChanges();
    }
    function listenForPromiseChanges() {
      var state = _.get(promise, '$$state.status');

      var shouldCallHandler = activeState === undefined;

      switch (state) {
        case PROMISE_RESOLVED:
          shouldCallHandler = !isRenderingBlocked && !isPromiseHandled;
          isPromiseHandled = shouldCallHandler;
          activeState = PROMISE_RESOLVED;
          break;

        case PROMISE_REJECTED:
          shouldCallHandler = !isRenderingBlocked && !isPromiseHandled;
          isPromiseHandled = shouldCallHandler;
          activeState = PROMISE_REJECTED;
          break;

        default:
          activeState = PROMISE_PENDING;
          break;
      }

      if (activeState !== PROMISE_PENDING) {
        vm.onReady();
        vm.onReady = angular.noop;
      }

      if (promise && activeState === PROMISE_PENDING) {
        promise.then(listenForPromiseChanges, listenForPromiseChanges);
      }

      if (shouldCallHandler) {
        vm[PROMISE_HANDLERS[activeState]].apply(null, arguments);
      }

      if (activeState === PROMISE_REJECTED) {
        console.error(arguments[0]);
      }
    }

    function blockRendering() {
      isRenderingBlocked = true;
    }
    function allowRendering() {
      isRenderingBlocked = false;
      listenForPromiseChanges();
    }
  }
})();
