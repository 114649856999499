(function () {
  'use strict';

  const SPORT_ID = {
    HOCKEY: 4,
    FOOTBALL: 1,
  };

  const SPORT_SLUG = {
    [SPORT_ID.HOCKEY]: 'hockey',
    [SPORT_ID.FOOTBALL]: 'football',
  };

  angular.module('app.common').constant('SPORT_ID', SPORT_ID).constant('SPORT_SLUG', SPORT_SLUG);
})();
